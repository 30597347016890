import React from "react"
import CaseAnalis from "./case_analis"
import Sjt from "./situational_judgment"
import Inbasket from "./inbasket"

const index = props => {
  const jenisSoal = props.jenisSoal

  console.log(props)

  return (
    <div>
      {jenisSoal === "studi kasus" ? (
        <CaseAnalis
          idLayout={props.idLayout}
          data={props.data}
          dataSoal={props.dataSoal}
          soalPetunjuk={props.soalPetunjuk}
          idAlattest={props.idAlattest}
          idSubtest={props.idSubtest}
          jenisSoal={props.jenisSoal}
          skenario={props.skenario}
          waktu={props.waktu}
          customLayout={props.customLayout}
          statusTimer={props.statusTimer}
          subTest={props.subTest}
          instruksi={props.instruksi}
        />
      ) : jenisSoal === "skenario" ? (
        <Sjt
          idLayout={props.idLayout}
          data={props.data}
          dataSoal={props.dataSoal}
          soalPetunjuk={props.soalPetunjuk}
          skenario={props.skenario}
          idAlattest={props.idAlattest}
          idSubtest={props.idSubtest}
          jenisSoal={props.jenisSoal}
          waktu={props.waktu}
          customLayout={props.customLayout}
          statusTimer={props.statusTimer}
          subTest={props.subTest}
          instruksi={props.instruksi}
        />
      ) : jenisSoal === "inbasket" ? (
        <Inbasket
          idLayout={props.idLayout}
          data={props.data}
          dataSoal={props.dataSoal}
          soalPetunjuk={props.soalPetunjuk}
          skenario={props.skenario}
          idAlattest={props.idAlattest}
          idSubtest={props.idSubtest}
          jenisSoal={props.jenisSoal}
          listSosmed={props.listSosmed}
          waktu={props.waktu}
          customLayout={props.customLayout}
          statusTimer={props.statusTimer}
          subTest={props.subTest}
          instruksi={props.instruksi}
        />
      ) : (
        ""
      )}
    </div>
  )
}

export default index

import React, { useState, useEffect } from "react"
import soal_inbasketemail from "../SoalInBasket"
import list_sosmed from "../SoalInBasket/list_sosmed"
import SoalEmail from "./TipeSoal/multiple_choice"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Collapse,
} from "reactstrap"
import email from "../../../../src/assets/images/sosial_media/email.svg"
import memo from "../../../../src/assets/images/sosial_media/memo.png"
import whatsapp from "../../../../src/assets/images/sosial_media/Whatsapp.svg"
import instagram from "../../../../src/assets/images/sosial_media/Instagram.png"
import facebook from "../../../../src/assets/images/sosial_media/Facebook.svg"
import twitter from "../../../../src/assets/images/sosial_media/Twitter.svg"
import kalender from "../../../../src/assets/images/sosial_media/kalender.svg"
import other from "../../../../src/assets/images/sosial_media/other.svg"

import index from "."
import MultipleChoice from "../SoalMultipleChoice/tipeA"
import Matrix from "../SoalMatrixQuestion/tipeA"
import classnames from "classnames"
import CountdownTimer from "components/CountdownTimer/CountdownTimer"
const MemoizedCountdownTimer = React.memo(CountdownTimer)
const Inbasket = ({
  idLayout,
  data,
  skenario,
  idAlattest,
  idSubtest,
  jenisSoal,
  listSosmed,
  dataSoal,
  soalPetunjuk,
  waktu,
  customLayout,
  statusTimer,
  subtest,
  instruksi,
}) => {
  const [sosmed, setSosmed] = useState()
  const [filteredData, setFiltereData] = useState([])
  const [idPaketSosmed, setIdPaketSosmed] = useState()
  // const handleDataFromChild = idPaketSosmed => {
  //   console.log("ID Paket Sosmed diterima dari child:", idPaketSosmed)
  // }
  const subTest = subtest

  useEffect(() => {
    const result = listSosmed.filter(
      item => item.id_paket_sosmed === idPaketSosmed
    )
    if (result[0] && result[0].sosmeds) {
      setFiltereData(result[0].sosmeds)
    }
    console.log("hasil :", result)
  }, [idPaketSosmed])

  const [openAccordions, setOpenAccordions] = useState([])
  const [statusTImer, setStatusTimer] = useState(false)
  const [showInstruksi, setShowInstruksi] = useState(true)
  const [showTimer, setShowTimer] = useState(true)
  const [openAccordionsInstruksi, setOpenAccordionsInstruksi] = useState(true)
  const [kondisiSoalState, setKondisiSoalState] = useState(true)

  const handleKondisiSoal = newKondisiSoal => {
    setKondisiSoalState(newKondisiSoal)
    // Anda dapat melakukan tindakan tambahan di sini, misalnya mengatur timer atau logika lainnya
  }

  const toggleAccordion = index => {
    const updatedOpenAccordions = [...openAccordions]
    updatedOpenAccordions[index] = !updatedOpenAccordions[index]
    setOpenAccordions(updatedOpenAccordions)
  }

  const toggleAccordionInstruksi = () => {
    setOpenAccordionsInstruksi(openAccordionsInstruksi === true ? false : true)
  }

  const [soalState, setSoalState] = useState(6)
  const [isExpanded, setIsExpanded] = useState(false)
  const [isFullScreen, setFullScreen] = useState(false)
  const [petunjukStatus, setPetunjukStatus] = useState(true)

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded)
  }

  const handleFullScreenToggle = () => {
    setFullScreen(!isFullScreen)
  }

  const toggleInstruksiVisibility = () => {
    setShowInstruksi(!showInstruksi)
  }

  const toggleTimerVisibility = () => {
    setShowTimer(!showTimer)
  }

  // useEffect(() => {
  //   const sosmedView = filteredData[0].sosial_media
  //   setSosmed(sosmedView)
  // }, [])

  // useEffect(() => {
  //   const sosmedView = filteredData[0].sosial_media
  //   setSosmed(sosmedView)
  // }, [])

  const addBootstrapClassesToHtml = html => {
    const wrapper = document.createElement("div")
    wrapper.innerHTML = html

    // Menambahkan kelas img-fluid pada semua elemen img
    const images = wrapper.querySelectorAll("img")
    images.forEach(img => {
      img.classList.add("img-fluid") // Gambar responsif
      img.style.maxHeight = "600px" // Atur tinggi maksimal
      img.style.objectFit = "contain" // Sesuaikan isi tanpa merusak aspek rasio
    })

    // Menambahkan kelas untuk tabel
    const tables = wrapper.querySelectorAll("table")
    tables.forEach((table, index) => {
      table.classList.add("table", "table-striped", "table-hover") // Tabel responsif dengan gaya Bootstrap

      // Jika ada lebih dari satu tabel, tambahkan margin-bottom pada setiap tabel
      if (tables.length > 1 && index !== tables.length - 1) {
        table.classList.add("mb-3")
      }

      // Bungkus tabel dalam div dengan overflow responsif
      const tableWrapper = document.createElement("div")
      tableWrapper.classList.add("table-responsive")
      tableWrapper.style.overflowX = "auto"
      tableWrapper.style.overflowY = "auto"

      table.parentNode.insertBefore(tableWrapper, table)
      tableWrapper.appendChild(table)
    })

    return wrapper.innerHTML
  }

  return (
    <div>
      <Row>
        <div className="alert alert-primary w-100 d-flex align-items-center">
          <p className="fs-5 fw-medium ms-3" style={{ lineHeight: "1" }}>
            <i className="mdi mdi-alert-circle" style={{ lineHeight: "1" }}></i>{" "}
            Soal bentuk {jenisSoal}
          </p>
        </div>
        <div className="accordion m-3" id="accordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className={classnames("accordion-button", "fw-medium", {
                  collapsed: !openAccordionsInstruksi,
                })}
                type="button"
                onClick={() => toggleAccordionInstruksi()}
                style={{ cursor: "pointer" }}
              >
                Instruksi
              </button>
            </h2>

            <Collapse
              isOpen={openAccordionsInstruksi}
              className="accordion-collapse"
            >
              <div className="accordion-body">
                <CardBody className="bg-light rounded rounded-3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: instruksi,
                    }}
                  />
                </CardBody>
              </div>
            </Collapse>
          </div>
        </div>
        {waktu && kondisiSoalState === false && (
          <div className="alert alert-primary d-flex align-items-center justify-content-center">
            <p
              className="fs-5 fw-medium ms-3 text-center"
              style={{ lineHeight: "1" }}
            >
              <MemoizedCountdownTimer
                durationInMinutes={waktu}
                setStatusTimer={setStatusTimer}
              />
            </p>
          </div>
        )}
      </Row>
      <Row>
        <Col xl={1}>
          <Card className="bg-transparent border border-primary shadow shadow-lg d-flex flex-column mh-25 full-height">
            <CardBody
              className="text-center ms-3"
              style={{ flex: 1, overflow: "auto" }}
            >
              {[...new Set(filteredData.map(item => item.sosial_media))].map(
                (sosialMedia, index) => (
                  <a
                    key={index}
                    className="btn btn-transparant me-3"
                    role="button"
                    onClick={() => setSosmed(sosialMedia)}
                  >
                    <img
                      className="email"
                      src={
                        sosialMedia === "Wa"
                          ? whatsapp
                          : sosialMedia === "Memo"
                          ? memo
                          : sosialMedia === "Twitter"
                          ? twitter
                          : sosialMedia === "Email"
                          ? email
                          : sosialMedia === "Facebook"
                          ? facebook
                          : sosialMedia === "Instagram"
                          ? instagram
                          : sosialMedia === "Kalender"
                          ? kalender
                          : sosialMedia === "Lainnya"
                          ? other
                          : ""
                      }
                      alt={sosialMedia}
                    />
                  </a>
                )
              )}
            </CardBody>
          </Card>
        </Col>

        <Col xl={isFullScreen ? 11 : isExpanded ? 5 : 4}>
          <Card className="bg-transparent border border-primary shadow shadow-lg d-flex flex-column mh-25 full-height">
            <CardBody style={{ flex: 1, overflow: "auto" }}>
              <div className="d-flex justify-content-between align-items-center">
                <label className="fs-5 fw-semibold">
                  Sosial Media: {sosmed}
                </label>
                <button
                  type="button"
                  onClick={() => handleFullScreenToggle()}
                  className="btn noti-icon text-blue float-end"
                >
                  <i className="bx bx-fullscreen" style={{ color: "blue" }} />
                </button>
              </div>
              {!isExpanded &&
                filteredData.map((item, index) => (
                  <div key={index}>
                    {item.sosial_media === sosmed && (
                      <div className="accordion m-3" id="accordion">
                        <div className="accordion-item" key={index}>
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className={classnames(
                                "accordion-button",
                                "fw-medium",
                                { collapsed: !openAccordions[index] }
                              )}
                              type="button"
                              onClick={() => toggleAccordion(index)}
                              style={{ cursor: "pointer" }}
                            >
                              {item.judul}
                            </button>
                          </h2>
                          <Collapse
                            isOpen={openAccordions[index]}
                            className="accordion-collapse"
                          >
                            <div className="accordion-body">
                              <CardBody className="bg-light rounded rounded-3">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: addBootstrapClassesToHtml(item.isi),
                                  }}
                                />
                              </CardBody>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </CardBody>
          </Card>
        </Col>

        <Col xl={isExpanded ? 6 : 7} className={isFullScreen ? "d-none" : ""}>
          <Card className="bg-transparent border border-primary shadow shadow-lg d-flex flex-column mh-25 full-height">
            <CardBody style={{ flex: 1, overflow: "auto" }}>
              {idLayout === 1 ||
              idLayout === 2 ||
              idLayout === 3 ||
              idLayout === 4 ? (
                <MultipleChoice
                  idAlattest={idAlattest}
                  idSubtest={idSubtest}
                  data={data}
                  dataSoal={dataSoal}
                  soalPetunjuk={soalPetunjuk}
                  jenisSoal={jenisSoal}
                  waktu={waktu}
                  customLayout={customLayout}
                  statusTimer={statusTimer}
                  subtest={subtest}
                  showInstruksi={showInstruksi}
                  showTimer={showTimer}
                  isExpand={isExpanded} // Pass the state
                  onExpandToggle={handleExpandToggle} // Pass the callback
                  onKondisiSoalChange={handleKondisiSoal}
                  sendDataToParent={setIdPaketSosmed}
                />
              ) : idLayout === 6 || idLayout === 14 ? (
                <Matrix
                  idLayout={idLayout}
                  data={data}
                  dataSoal={dataSoal}
                  soalPetunjuk={soalPetunjuk}
                  skenario={skenario}
                  idAlattest={idAlattest}
                  idSubtest={idSubtest}
                  waktu={waktu}
                  customLayout={customLayout}
                  subtest={subtest}
                  showInstruksi={showInstruksi}
                  showTimer={showTimer}
                  isExpand={isExpanded} // Pass the state
                  onExpandToggle={handleExpandToggle} // Pass the callback
                  onKondisiSoalChange={handleKondisiSoal}
                  sendDataToParent={setIdPaketSosmed}
                />
              ) : (
                ""
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Inbasket

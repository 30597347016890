import CryptoJS from "crypto-js"

const encryptData = (jsonData, secretKey) => {
  const jsonString = JSON.stringify(jsonData)

  // Encrypt the JSON data
  const encryptedData = CryptoJS.AES.encrypt(jsonString, secretKey).toString()

  // Encode the encrypted data to make it URL-safe
  const urlSafeEncryptedData = encodeURIComponent(encryptedData)

  return urlSafeEncryptedData
}

export default encryptData

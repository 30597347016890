import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Input,
  CardHeader,
  Collapse,
} from "reactstrap"
import { useParams, useNavigate } from "react-router-dom"
import _ from "lodash"
import SoalMultipleChoice from "./TipeSoal/muliple_ca"
import SoalBipolar from "../SoalBipolar/index"
import SoalMatrix from "./TipeSoal/matrix_ca"
import SoalText from "../SoalText/soal_text"
import SoalBertingkat from "../SoalBei/index"
import SoalMengurtkan from "../SoalRankingQuestion/index"
import { data } from "autoprefixer"
import CountdownTimer from "components/CountdownTimer/CountdownTimer"
import classnames from "classnames"
const MemoizedCountdownTimer = React.memo(CountdownTimer)

const case_analis = props => {
  const [indexSoal, setIndexSoal] = useState(1)
  const [activeIndex, setActiveIndex] = useState()
  const [urutanCase, setUrutanCase] = useState(0)
  const [caseId, setCaseId] = useState(0)
  const [petunjuk, setPetunjuk] = useState()
  const [selectedOptions, setSelectedOptions] = useState([])
  const [statusTImer, setStatusTimer] = useState(false)
  const customLayout = props.customLayout
  const subTest = props.subTest
  const instruksi = props.instruksi
  const dataSoal = props.dataSoal
  const soalPetunjuk = props.soalPetunjuk

  const { detail } = useParams()
  const navigate = useNavigate()

  const uniqueCaseIds = [...new Set(props.data.map(item => item.case_id))]
  //ambil case yang berbeda
  const filteredData = uniqueCaseIds.map(caseId => {
    return props.data.find(item => item.case_id === caseId)
  })
  const sortedData = _.sortBy(filteredData, ["case.case_id"])

  useEffect(() => {
    const idCase = sortedData[urutanCase].case_id
    setCaseId(idCase)
  }, [sortedData, urutanCase])

  const jumlahCase = sortedData.length

  const checkListSoal = caseId => {
    const foundItem = props.data.find(item => item.case_id === caseId)
    if (foundItem) {
      const index = uniqueCaseIds.indexOf(caseId)
      setUrutanCase(index)
    } else {
      console.log(`Case ID: ${caseId} not found.`)
    }
  }

  const checkedSoal = (layout, id_msoal, index) => {
    if (layout == 1 || layout == 2 || layout == 3 || layout == 4) {
      selectedOptions.findIndex(option => option.id_msoal === id_msoal) ===
        index
    } else if (layout == 6) {
      selectedOptions.some(option => option.id_msoal === id_msoal)
    }
  }

  const customLayoutCardSoal = {
    backgroundColor: customLayout?.bgcolor_soal,
  }

  const customLayoutSoal = {
    fontFamily: customLayout?.jenis_font_soal,
    color: customLayout?.textcolor_soal,
    textAlign: customLayout?.textalign_soal,
  }

  const customLayoutCardJawaban = {
    backgroundColor: customLayout?.bgcolor_jawaban,
  }

  const customLayoutJawaban = {
    fontFamily: customLayout?.jenis_font_jawaban,
    color: customLayout?.textcolor_jawaban,
    textAlign: customLayout?.textalign_jawaban,
  }

  const [openAccordions, setOpenAccordions] = useState(true)

  const toggleAccordion = () => {
    setOpenAccordions(openAccordions === true ? false : true)
  }

  const [soalState, setSoalState] = useState(6)
  const [isExpanded, setIsExpanded] = useState(false)
  const [petunjukStatus, setPetunjukStatus] = useState(true)

  const expand = () => {
    setIsExpanded(!isExpanded) // Toggle expand state
    setSoalState(isExpanded ? 6 : 12) // Atur lebar kolom berdasarkan state expand
  }

  return (
    <Container fluid>
      <Row>
        <div className="accordion m-3" id="accordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className={classnames("accordion-button", "fw-medium", "fs-1", {
                  collapsed: !openAccordions,
                })}
                type="button"
                onClick={() => toggleAccordion()}
                style={{ cursor: "pointer" }}
              >
                Instruksi
              </button>
            </h2>

            <Collapse isOpen={openAccordions} className="accordion-collapse">
              <div className="accordion-body">
                <CardBody className="bg-light rounded rounded-3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: instruksi,
                    }}
                  />
                </CardBody>
              </div>
            </Collapse>
          </div>
        </div>
        {petunjuk === false && (
          <div className="alert alert-primary d-flex align-items-center justify-content-center">
            <p
              className="fs-5 fw-medium ms-3 text-center"
              style={{ lineHeight: "1" }}
            >
              <MemoizedCountdownTimer
                durationInMinutes={props.waktu}
                setStatusTimer={setStatusTimer}
              />
            </p>
          </div>
        )}
      </Row>
      <div className="mt-3">
        <hr size="5" width="100%" className="bg-primary" />
      </div>
      <Row>
        <Col xl={isExpanded ? 6 : 4}>
          <Card className="bg-transparent border border-primary shadow shadow-lg d-flex flex-column mh-25 full-height">
            <CardHeader className="fs-5 fw-semibold bg-primary text-light">
              {sortedData[urutanCase].case.case_name}
            </CardHeader>
            <CardBody style={{ flex: 1, overflow: "auto" }}>
              <div
                className="mt-2 mh-25"
                dangerouslySetInnerHTML={{
                  __html: sortedData[urutanCase].case.case,
                }}
              ></div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={isExpanded ? 6 : soalState}>
          <Card className="bg-transparent border border-primary shadow shadow-lg d-flex flex-column mh-25 full-height">
            <CardHeader className="bg-primary fs-5 fw-semibold text-light ">
              Soal
              {!petunjuk && (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      expand()
                    }}
                    className="btn noti-icon text-white float-end"
                  >
                    <i
                      className="bx bx-fullscreen"
                      style={{ color: "white" }}
                    />
                  </button>
                </>
              )}
            </CardHeader>
            <CardBody style={{ flex: 1, overflow: "auto" }}>
              {props.idLayout === 1 ||
                props.idLayout === 2 ||
                props.idLayout === 3 ||
                props.idLayout === 4 ? (
                <SoalMultipleChoice
                  idAlattest={props.idAlattest}
                  idSubtest={props.idSubtest}
                  data={props.data}
                  dataSoal={dataSoal}
                  soalPetunjuk={soalPetunjuk}
                  jenisSoal={props.jenisSoal}
                  caseId={caseId}
                  setUrutanCase={setUrutanCase}
                  urutanCase={urutanCase}
                  jumlahCase={jumlahCase}
                  setOptions={setSelectedOptions}
                  setPetunjuk={setPetunjuk}
                  statusTImer={statusTImer}
                  customLayout={customLayout}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                />
              ) : props.idLayout === 6 || props.idLayout === 14 ? (
                <SoalMatrix
                  idAlattest={props.idAlattest}
                  idSubtest={props.idSubtest}
                  data={props.data}
                  dataSoal={dataSoal}
                  soalPetunjuk={soalPetunjuk}
                  jenisSoal={props.jenisSoal}
                  caseId={caseId}
                  setUrutanCase={setUrutanCase}
                  urutanCase={urutanCase}
                  jumlahCase={jumlahCase}
                  setOptions={setSelectedOptions}
                  setPetunjuk={setPetunjuk}
                  statusTImer={statusTImer}
                  customLayout={customLayout}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                />
              ) : (
                ""
              )}
            </CardBody>
          </Card>
        </Col>
        {props.jenisSoal === "studi kasus" && !isExpanded ? (
          <Col xl={2}>
            <Card className="bg-transparent border border-primary shadow shadow-lg d-flex flex-column mh-26 full-height">
              <CardHeader className="fs-5 fw-semibold bg-primary text-light">
                Nomer Soal
              </CardHeader>
              <CardBody style={{ flex: 1, overflow: "auto" }}>
                <Row>
                  {petunjuk
                    ? // Jika petunjuk adalah true, gunakan dataSoal
                    soalPetunjuk.map((item, index) => (
                      <Col xl={2} key={index}>
                        <label className="card-radio-label">
                          <input
                            type="radio"
                            onClick={() => checkListSoal(item.case_id)}
                            className="card-radio-input"
                            checked={
                              props.idLayout === 1 ||
                                props.idLayout === 2 ||
                                props.idLayout === 3 ||
                                props.idLayout === 4
                                ? selectedOptions.some(
                                  option =>
                                    option.id_msoal === item.id_msoal
                                )
                                : props.idLayout === 6
                                  ? selectedOptions.some(
                                    option =>
                                      option.id_msoal === item.id_msoal
                                  )
                                  : ""
                            }
                          />
                          <div className="card-radio">{index + 1}</div>
                        </label>
                      </Col>
                    ))
                    : // Jika petunjuk adalah false, gunakan soalPetunjuk
                    dataSoal.map((item, index) => (
                      <Col xl={2} key={index}>
                        <label className="card-radio-label">
                          <input
                            type="radio"
                            onClick={() => checkListSoal(item.case_id)}
                            className="card-radio-input"
                            checked={
                              props.idLayout === 1 ||
                                props.idLayout === 2 ||
                                props.idLayout === 3 ||
                                props.idLayout === 4
                                ? selectedOptions.some(
                                  option =>
                                    option.id_msoal === item.id_msoal
                                )
                                : props.idLayout === 6
                                  ? selectedOptions.some(
                                    option =>
                                      option.id_msoal === item.id_msoal
                                  )
                                  : ""
                            }
                          />
                          <div className="card-radio">{index + 1}</div>
                        </label>
                      </Col>
                    ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        ) : (
          ""
        )}
      </Row>
    </Container>
  )
}

export default case_analis
